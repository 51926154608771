import React, { useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import compLogo from "../src/assests/aruvi_newlogo.png";
import { FiLogOut } from "react-icons/fi";
import "./landing.css";
import { AiFillHome } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";
import { MdAirplaneTicket, MdOutlineCurrencyRupee } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";

const { Content, Sider } = Layout;

const items = [
  {
    key: "home",
    icon: <AiFillHome />,
    label: "Home",
  },
  {
    key: "client",
    icon: <BsPeopleFill />,
    label: "Client",
  },
  {
    key: "ticketing",
    icon: <MdAirplaneTicket />,
    label: "Ticketing",
  },
  {
    key: "payment",
    icon: <MdOutlineCurrencyRupee />,
    label: "Payment",
  },
  {
    key: "report",
    icon: <BiSolidReport />,
    label: "Report",
  },
];

const Landing = () => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState("home");
  const storedUsername = localStorage.getItem("username");

  const handleMenuClick = ({ key }) => {
    navigate(`/app/${key}`);
    setSelectedKey(key);
  };

  const handleLogout = () => {
    console.log("Logout clicked");
    navigate("/");
  };

  const user = {
    name: storedUsername || "Admin",
    initials: storedUsername ? storedUsername.charAt(0).toUpperCase() : "A",
  };

  return (
    <Layout>
      <Sider className="siderbar" breakpoint="lg" collapsedWidth="80">
        <div className="h-screen siderbar">
          <div className="flex-1 flex flex-col h-full">
            <div className="flex flex-col flex-1">
              <div className="flex-grow">
                <div className="top-cus-logo-section">
                  <img src={compLogo} alt="Profile Logo" className="logo" />
                  <p className="com-title">Aruvi</p>
                </div>

                <div>
                  <Menu
                    className="menu-scroll-container"
                    mode={"inline"}
                    items={items}
                    onClick={handleMenuClick}
                    selectedKeys={[selectedKey]}
                  />
                </div>
              </div>
              {/* Bottom Button Group */}
              <div className="p-3 flex justify-between items-center bg-[white]">
                <Avatar
                  className="mr-4 bg-[#2b662b] flex justify-center items-center"
                  size="small"
                >
                  <span className="cus-prof-name text-white" style={{ position: 'relative', top: '-1px' }}>
                    {user.initials}
                  </span>
                </Avatar>

                <div className="flex-grow cus-prof-name">{user.name}</div>
                <FiLogOut
                  className="ml-4 text-xl hover:text-[#2b662b]"
                  onClick={handleLogout}
                />
              </div>
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Landing;
