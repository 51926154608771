import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./app/Login/Login";
import Landing from "./landing";
import Client from "./app/Client/Client";
import Home from "./app/Home/Home";
import Ticketing from "./app/Ticketing/Ticketing";
import Report from "./app/Report/Report";
import Payment from "./app/Payment/Payment";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/app" element={<Landing />}>
          <Route path="home" element={<Home />} />
          <Route path="client" element={<Client />} />
          <Route path="ticketing" element={<Ticketing />} />
          <Route path="payment" element={<Payment />} />
          <Route path="report" element={<Report />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
