import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import { getApi } from "../../utils/getApiMethod";
import { FilterOutlined } from "@ant-design/icons";
import { BiReset } from "react-icons/bi";

const { Title, Text } = Typography;
const { Option } = Select;

const Home = () => {
  const [bookingList, setBookingList] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const getBookingsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setBookingList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setClientsList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const bookingUrl = "booking";
    const clientUrl = "client";
    getBookingsList(bookingUrl);
    getClientsList(clientUrl);
  }, []);

  const calculateTotals = () => {
    let totalClients = new Set();
    let totalValue = 0;
    let totalPending = 0;
    let totalInvoiced = 0;

    bookingList.forEach((booking) => {
      totalClients.add(booking.clientname);

      // Add total value
      totalValue += parseFloat(booking.total);

      // Add pending amount
      if (booking.amountpendingstatus === "pending") {
        totalPending += parseFloat(booking.amountpending);
      }

      // Add invoiced amount
      if (booking.amountreceivedstatus === "invoiced") {
        totalInvoiced += parseFloat(booking.amountreceived);
      }
    });

    return {
      totalClients: totalClients.size,
      totalValue,
      totalPending,
      totalInvoiced,
    };
  };

  const totals = calculateTotals();

  const [bookingFilterForm] = Form.useForm();
  const [year, setYear] = useState("");
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");

  const handleNotification = (message) => {
    notification.error({
      message: "Error",
      description: message,
      placement: "topRight",
    });
  };

  const handleSubmit = async () => {
    if (!year || !fromMonth || !toMonth) {
      handleNotification("Please fill in all fields.");
      return;
    }

    const from = parseInt(fromMonth);
    const to = parseInt(toMonth);
    if (isNaN(from) || isNaN(to) || from < 1 || to > 12 || from > to) {
      handleNotification(
        "Please enter valid months (1-12) and ensure fromMonth is less than or equal to toMonth."
      );
      return;
    }

    try {
      const response = await getApi(
        `filterbooking?year=${year}&frommonth=${from}&tomonth=${to}`
      );
      setBookingList(response);
    } catch (error) {
      handleNotification("Failed to fetch data: " + error.message);
    }
  };

  const handleReset = () => {
    bookingFilterForm.resetFields();
    setYear("");
    setFromMonth("");
    setToMonth("");
    const url = "booking";
    getBookingsList(url);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Form
        form={bookingFilterForm}
        layout="inline"
        onFinish={handleSubmit}
        className="pt-5 pb-5 pl-2 border border-gray-200 rounded-lg"
        style={{
          background: "linear-gradient(to right, #82e082, #226aa9)",
        }}
      >
        <div className="flex justify-between w-full">
          {/* Left-aligned Fields */}
          <div className="flex gap-2">
            <Title level={4} className="ml-4">
              <span className="text-white">Booking Summary</span>
            </Title>
          </div>

          {/* Right-aligned Buttons */}
          <div className="flex items-center gap-2">
            <Form.Item name="year">
              <Select
                style={{ width: 80 }}
                placeholder="Year"
                value={year}
                onChange={setYear}
              >
                {[2022, 2023, 2024, 2025].map((y) => (
                  <Option key={y} value={y}>
                    {y}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="fromMonth">
              <Select
                style={{ width: 120 }}
                placeholder="From"
                value={fromMonth}
                onChange={setFromMonth}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <Option key={index + 1} value={index + 1}>
                    {month}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="toMonth">
              <Select
                style={{ width: 120 }}
                placeholder="To"
                value={toMonth}
                onChange={setToMonth}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <Option key={index + 1} value={index + 1}>
                    {month}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                className="cus-btn"
                icon={<FilterOutlined className="mr-1" />}
              >
                Filter
              </Button>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                onClick={handleReset}
                className="cus-btn"
                icon={<BiReset className="text-lg" />}
              >
                Reset
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>

      <div className="mt-4">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <Card
              hoverable
              bordered={false}
              style={{
                backgroundColor: "#f5faf3",
                textAlign: "center",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <Title level={4} style={{ marginBottom: "10px" }}>
                Total Clients
              </Title>
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {clientsList.length}
              </Text>
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card
              hoverable
              bordered={false}
              style={{
                backgroundColor: "#e6f7ff",
                textAlign: "center",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <Title level={4} style={{ marginBottom: "10px" }}>
                Total Value
              </Title>
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {/* ₹{totals.totalValue.toFixed(2)} */}₹
                {new Intl.NumberFormat("en-IN").format(
                  totals.totalValue.toFixed(2)
                )}
              </Text>
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card
              hoverable
              bordered={false}
              style={{
                backgroundColor: "#fff7e6",
                textAlign: "center",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <Title level={4} style={{ marginBottom: "10px" }}>
                Total Pending
              </Title>
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {/* ₹{totals.totalPending.toFixed(2)} */}₹
                {new Intl.NumberFormat("en-IN").format(
                  totals.totalPending.toFixed(2)
                )}
              </Text>
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card
              hoverable
              bordered={false}
              style={{
                backgroundColor: "#f6ffed",
                textAlign: "center",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <Title level={4} style={{ marginBottom: "10px" }}>
                Total Invoiced
              </Title>
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {/* ₹{totals.totalInvoiced.toFixed(2)} */}₹
                {new Intl.NumberFormat("en-IN").format(
                  totals.totalInvoiced.toFixed(2)
                )}
              </Text>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
