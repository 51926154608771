import React from "react";
import { Controller } from "react-hook-form";
import { Input, Select, Switch, DatePicker, TimePicker, Button } from "antd";

const TicketingFormComponent = ({
  formFields,
  handleSubmit,
  control,
  errors,
  onSubmit,
  buttonName,
  buttonLoading,
  travelType,
}) => {
  const bookingFields = [
    "bookingdate",
    "clientname",
    "passengername",
    "noofpeople",
    "pnr",
  ];
  const oneWayFields = [
    "departuretraveldate",
    "departuresector",
    "departureairlines",
    "departureflightnumber",
    "departurestarttime",
    "departureendtime",
  ];
  const roundTripFields = [
    "returntraveldate",
    "returnsector",
    "returnairlines",
    "returnflightnumber",
    "returnstarttime",
    "returnendtime",
  ];

  const otherFields = formFields.filter(
    ({ name }) =>
      !bookingFields.includes(name) &&
      !oneWayFields.includes(name) &&
      !roundTripFields.includes(name)
  );

  const renderFields = (fields) =>
    fields.map(
      ({ name, label, type, icon: Icon, options, multiple, editable }) => (
        <div key={name} className="flex flex-col space-y-2">
          <label className="form-heading flex items-center space-x-2">
            {Icon && <Icon className="text-xl" />}
            <span>{label}</span>
          </label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              if (type === "input") {
                return (
                  <Input
                    {...field}
                    disabled={!editable}
                    size="large"
                    className="border-gray-300 rounded-md"
                  />
                );
              } else if (type === "select") {
                return (
                  <Select
                    {...field}
                    size="large"
                    className="border-gray-300 rounded-md"
                    mode={multiple ? "multiple" : null}
                    value={field.value || (multiple ? [] : "")}
                    onChange={(value) => field.onChange(value)}
                  >
                    {options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div className="flex items-center space-x-2">
                          <option.icon style={{ color: option.color }} />
                          <span>{option.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                );
              } else if (type === "switch") {
                return <Switch {...field} style={{ width: "30px" }} />;
              } else if (type === "date") {
                return (
                  <DatePicker
                    {...field}
                    size="large"
                    className="border-gray-300 rounded-md w-full"
                    onChange={(date) => field.onChange(date)}
                  />
                );
              } else if (type === "time") {
                return (
                  <TimePicker
                    {...field}
                    size="large"
                    className="border-gray-300 rounded-md w-full"
                    onChange={(time) => field.onChange(time)}
                  />
                );
              }
            }}
          />
          {errors[name] && (
            <p className="text-red-500 text-sm">{errors[name].message}</p>
          )}
        </div>
      )
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mb-4">
      {/* Ticketing Details Section */}
      <div className="form-section">
        <h2 className="form-sec-heading">Booking Details</h2>
        <div className="mt-2 p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderFields(
            formFields.filter(({ name }) => bookingFields.includes(name))
          )}
        </div>
      </div>

      {/* One-Way Section */}
      <div className="form-section">
        <h2 className="form-sec-heading">Departure Details</h2>
        <div className="mt-2 p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderFields(
            formFields.filter(({ name }) => oneWayFields.includes(name))
          )}
        </div>
      </div>

      {/* Roundtrip Section */}
      {travelType === "roundtrip" && (
        <div className="form-section">
          <h2 className="form-sec-heading">Return Details</h2>
          <div className="mt-2 p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {renderFields(
              formFields.filter(({ name }) => roundTripFields.includes(name))
            )}
          </div>
        </div>
      )}

      {/* Other Details Section */}
      <div className="form-section">
        <h2 className="form-sec-heading">Ticketing Details</h2>
        <div className="mt-2 p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderFields(otherFields)}
        </div>
      </div>

      {/* Submit Button */}
      <div className="mt-4 flex justify-end">
        <Button
          type="primary"
          htmlType="submit"
          className="cus-btn"
          loading={buttonLoading}
        >
          <span>{buttonName}</span>
        </Button>
      </div>
    </form>
  );
};

export default TicketingFormComponent;
