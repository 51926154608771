export const getApi = async (url) => {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  // const fullUrl = `${baseUrl}${url}`;
  const fullUrl = `/api/${url}`; // Use the Vercel proxy here

  try {
    // console.log("Attempting to make a GET request...");

    const response = await fetch(fullUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // console.log("Response status:", response.status);

    // Check if the response status is in the 2xx range before parsing
    if (!response.ok) {
      console.error(
        "Server responded with an error:",
        response.status,
        response.statusText
      );
      const errorResult = await response.json();
      throw new Error(errorResult.message || "Something went wrong");
    }

    // Parse the JSON response if the status is OK
    const result = await response.json();
    // console.log("Parsed result:", result);

    return result;
  } catch (error) {
    // Catch network or other errors
    console.error("An error occurred:", error.message);
    throw new Error(error.message || "Network error");
  }
};
