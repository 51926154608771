import { Button, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormComponent from "../../components/Formcomponent";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { FaPhone } from "react-icons/fa";
import { getApi } from "../../utils/getApiMethod";
import { postApi } from "../../utils/postApiMethod";
import { putApi } from "../../utils/putApiMethod";

const schema = yup.object().shape({
  clientname: yup.string().required("Name is required"),
  clientphonenumber: yup.string().required("phone Number is required"),
  clientemail: yup.string().required("Email is required"),
});

const Client = () => {
  const [clientsList, setClientsList] = useState([]);
  const [isClientForm, setIsClientForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clientname: "",
      clientphonenumber: "",
      clientemail: "",
    },
  });

  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setClientsList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "client";
    getClientsList(url);
  }, []);

  const columns = [
    {
      title: "Client Id",
      dataIndex: "clientid",
      key: "clientid",
    },
    {
      title: "Name",
      dataIndex: "clientname",
      key: "clientname",
    },
    {
      title: "Phone Number",
      dataIndex: "clientphonenumber",
      key: "clientphonenumber",
    },
    {
      title: "Email",
      dataIndex: "clientemail",
      key: "clientemail",
    },
  ];

  const handleAddClick = () => {
    reset({
      clientname: "",
      clientemail: "",
      clientphonenumber: "",
    });
    setIsClientForm(true);
  };

  const handleDiscard = () => {
    reset({
      clientname: "",
      clientemail: "",
      clientphonenumber: "",
    });
    setIsClientForm(false);
    if (isEdit) {
      setIsEdit(false);
    }
  };

  const onSubmit = async (data) => {
    // console.log("Connection Data:", data);
    const payload = {
      clientname: data.clientname,
      clientphonenumber: data.clientphonenumber,
      clientemail: data.clientemail,
    };
    const url = isEdit ? `client/${currentId}` : "client";
    try {
      setIsLoading(true);
      const apiMethod = isEdit ? putApi : postApi;
      const response = await apiMethod(url, payload);
      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });
        const url = "client";
        getClientsList(url);
        setIsClientForm(false);
      } else if (response?.status_code === 404) {
        notification.warning({
          message: "Client Not Found...",
        });
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameClick = (record) => {
    // console.log("Resetting form with record data:", record);
    setIsClientForm(true);
    setCurrentId(record.clientid);
    setIsEdit(true);
    reset({
      clientname: record.clientname,
      clientphonenumber: record.clientphonenumber,
      clientemail: record.clientemail,
    });
  };

  const formFields = [
    { name: "clientname", label: "Name", type: "input", icon: AiOutlineUser },
    { name: "clientemail", label: "Email", type: "input", icon: AiOutlineMail },
    {
      name: "clientphonenumber",
      label: "Phone Number",
      type: "input",
      icon: FaPhone,
    },
  ];

  return (
    <>
      {isClientForm ? (
        <div className="flex space-x-6 p-2" style={{ height: "100vh" }}>
          <div className="flex-1">
            {/* Sticky header */}
            <div className="p-3 flex items-center justify-between sticky top-0 z-10 bg-white">
              <h2 className="com-heading">{`${
                isEdit ? "Edit" : "Create"
              } Client Form`}</h2>
              <Button className="cus-btn" onClick={handleDiscard}>
                Discard
              </Button>
            </div>

            {/* Scrollable content */}
            <div
              className="mt-2 p-3 overflow-y-auto bg-[#f5faf3]"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <FormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonLoading={isLoading}
                buttonName={"Save"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex p-2" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Sticky Header */}
            <div className="py-3 px-1 flex items-center justify-between rounded-md sticky top-0 z-10 bg-white">
              <h2 className="com-heading">Client List</h2>
              <Button className="cus-btn" onClick={handleAddClick}>
                Add
              </Button>
            </div>

            {/* Scrollable Table */}
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <Table
                dataSource={clientsList}
                columns={columns}
                scroll={{ x: "max-content" }}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleNameClick(record),
                })}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Client;
