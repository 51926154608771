import React from "react";
import { Controller } from "react-hook-form";
import { Input, Select, Button, Switch, DatePicker, TimePicker } from "antd";

const FormComponent = ({
  formFields,
  handleSubmit,
  control,
  errors,
  onSubmit,
  buttonName,
  buttonLoading,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mb-4">
      {formFields.map(
        ({ name, label, type, icon: Icon, options, multiple }) => {
          return (
            <div key={name} className="flex flex-col space-y-2">
              <label className="form-heading flex items-center space-x-2">
                {Icon && <Icon className="text-xl" />}
                <span>{label}</span>
              </label>
              <Controller
                name={name}
                control={control}
                render={({ field }) => {
                  if (type === "input") {
                    return (
                      <Input
                        {...field}
                        size="large"
                        className="border-gray-300 rounded-md"
                      />
                    );
                  } else if (type === "select") {
                    return (
                      <Select
                        {...field}
                        size="large"
                        className="border-gray-300 rounded-md"
                        mode={multiple ? "multiple" : null}
                        value={
                          field.value !== undefined
                            ? field.value
                            : multiple
                            ? []
                            : ""
                        }
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      >
                        {options.map((option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            <div className="flex items-center space-x-2">
                              <option.icon style={{ color: option.color }} />
                              <span>{option.label}</span>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    );
                  } else if (type === "switch") {
                    return (
                      <Switch
                        {...field}
                        style={{ width: "30px" }}
                        className="border-gray-300 rounded-xl"
                      />
                    );
                  } else if (type === "date") {
                    return (
                      <DatePicker
                        {...field}
                        size="large"
                        className="border-gray-300 rounded-md w-full"
                        onChange={(date) => field.onChange(date)}
                      />
                    );
                  } else if (type === "time") {
                    return (
                      <TimePicker
                        {...field}
                        size="large"
                        className="border-gray-300 rounded-md w-full"
                        onChange={(time) => field.onChange(time)}
                      />
                    );
                  } else if (type === "number") {
                    return (
                      <Input
                        {...field}
                        size="large"
                        type="number"
                        className="border-gray-300 rounded-md"
                      />
                    );
                  } else if (type === "para") {
                    return (
                      <div className="flex items-center space-x-2">
                        <span className="cus-content">{name}</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              />
              {errors[name] && (
                <p className="text-red-500 text-sm">{errors[name].message}</p>
              )}
            </div>
          );
        }
      )}
      <div className="mt-4 flex justify-end">
        <Button
          type="primary"
          htmlType="submit"
          className="cus-btn"
          loading={buttonLoading}
        >
          <span>{buttonName}</span>
        </Button>
      </div>
    </form>
  );
};

export default FormComponent;
