import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getApi } from "../../utils/getApiMethod";
import { postApi } from "../../utils/postApiMethod";
import { putApi } from "../../utils/putApiMethod";
import moment from "moment";
import {
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineNumber,
  AiOutlineClockCircle,
  AiOutlineFileText,
  AiOutlineTag,
  AiOutlineTeam,
  AiOutlineIdcard,
} from "react-icons/ai";
import { BiReset, BiRupee } from "react-icons/bi";
import { FilterOutlined } from "@ant-design/icons";
import { MdOutlineReceipt, MdPayment } from "react-icons/md";
import TicketingFormComponent from "../../components/TicketingFormComponent";

const schema = yup.object().shape({
  bookingdate: yup.string().required("Booking Date is required"),
  clientname: yup.string().required("Client Name is required"),
  passengername: yup.string().required("Passenger name is required"),
  noofpeople: yup
    .number()
    .required("Number of People is required")
    .positive()
    .integer(),
  departuretraveldate: yup
    .string()
    .required("Departure Travel Date is required"),
  departuresector: yup.string().required("Departure Sector is required"),
  pnr: yup.string().required("PNR is required"),
  departureairlines: yup.string().required("Departure Airlines is required"),
  departureflightnumber: yup
    .string()
    .required("Departure Flight Number is required"),
  departurestarttime: yup.string().required("Departure Start Time is required"),
  departureendtime: yup.string(),
  returntraveldate: yup.string(),
  returnsector: yup.string(),
  returnairlines: yup.string(),
  returnflightnumber: yup.string(),
  returnstarttime: yup.string(),
  returnendtime: yup.string(),
  fare: yup.number().required("Fare is required").positive(),
  fee: yup.number().required("Fee is required").positive(),
  gst: yup.number().required("GST is required").positive(),
  bookinggivenby: yup.string().required("Booking Given By is required"),
  ticketissuedby: yup.string().required("Ticket Issued By is required"),
  bookingportal: yup.string().required("Booking Portal is required"),
  amountreceived: yup.string().required("Amount Received is required"),
  // amountreceivedstatus: yup.string().required("Amount Received Status is required"),
  amountpending: yup.string().required("Amount Pending is required"),
  // amountpendingstatus: yup.string().required("Amount Pending Status is required"),
  // ticketstatus: yup.string().required("Ticket Status is required"),
  amountreceiving: yup.string().required("Amount Receiving is required"),
  paymentmode: yup.string().required("Payment Mode is required"),
  paymentreference: yup.string().required("Payment Reference is required"),
});

const Ticketing = () => {
  const [clientsList, setClientsList] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [isClientForm, setIsClientForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [travelType, setTravelType] = useState("oneway");

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bookingdate: "",
      clientname: "",
      passengername: "",
      noofpeople: "",
      departuretraveldate: "",
      departuresector: "",
      pnr: "",
      departureairlines: "",
      departureflightnumber: "",
      departurestarttime: "",
      departureendtime: "",
      returntraveldate: "",
      returnsector: "",
      returnairlines: "",
      returnflightnumber: "",
      returnstarttime: "",
      returnendtime: "",
      fare: "",
      fee: "",
      gst: "",
      bookinggivenby: "",
      ticketissuedby: "",
      bookingportal: "",
      amountreceived: "",
      amountpending: "",
      // ticketstatus: "",
      amountreceiving: "0",
      paymentmode: "N/A",
      paymentreference: "N/A",
    },
  });

  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        // console.log("result", result);
        setClientsList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "client";
    getClientsList(url);
  }, []);

  const clientOptions = clientsList.map((client) => ({
    label: client.clientname,
    value: client.clientname,
    icon: AiOutlineUser,
  }));

  const getBookingsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setBookingList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "booking";
    getBookingsList(url);
  }, []);

  const formatter = new Intl.NumberFormat("en-IN");

  const columns = [
    { title: "Booking Id", dataIndex: "bookingid", key: "bookingid" },
    {
      title: "Booking Date",
      dataIndex: "bookingdate",
      key: "bookingdate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    { title: "Client Name", dataIndex: "clientname", key: "clientname" },
    { title: "Passenger", dataIndex: "passengername", key: "passengername" },
    { title: "Number of People", dataIndex: "noofpeople", key: "noofpeople" },
    {
      title: "Departure Travel Date",
      dataIndex: "departuretraveldate",
      key: "departuretraveldate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Departure Sector",
      dataIndex: "departuresector",
      key: "departuresector",
    },
    {
      title: "Departure Airlines",
      dataIndex: "departureairlines",
      key: "departureairlines",
    },
    {
      title: "Departure Flight Number",
      dataIndex: "departureflightnumber",
      key: "departureflightnumber",
    },
    {
      title: "Departure Start Time",
      dataIndex: "departurestarttime",
      key: "departurestarttime",
      render: (time) => time.replace("Z", ""),
    },
    {
      title: "Departure End Time",
      dataIndex: "departureendtime",
      key: "departureendtime",
      render: (time) => time.replace("Z", ""),
    },
    {
      title: "Return Travel Date",
      dataIndex: "returntraveldate",
      key: "returntraveldate",
      render: (date) =>
        date === "9999-12-31" ? "" : moment(date).format("YYYY-MM-DD"),
    },
    { title: "Return Sector", dataIndex: "returnsector", key: "returnsector" },
    {
      title: "Return Airlines",
      dataIndex: "returnairlines",
      key: "returnairlines",
    },
    {
      title: "Return Flight Number",
      dataIndex: "returnflightnumber",
      key: "returnflightnumber",
    },
    {
      title: "Return Start Time",
      dataIndex: "returnstarttime",
      key: "returnstarttime",
      render: (text) => (text === "00:00:00Z" ? "" : text.replace("Z", "")),
    },
    {
      title: "Return End Time",
      dataIndex: "returnendtime",
      key: "returnendtime",
      render: (text) => (text === "00:00:00Z" ? "" : text.replace("Z", "")),
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "fare",
      render: (value) => formatter.format(value),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      render: (value) => formatter.format(value),
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      render: (value) => formatter.format(value),
    },
    {
      title: "Ticket Cost",
      dataIndex: "total",
      key: "total",
      render: (value) => formatter.format(value),
    },
    {
      title: "Amount Received",
      dataIndex: "amountreceived",
      key: "amountreceived",
      render: (value) => formatter.format(value),
    },
    {
      title: "Amount Pending",
      dataIndex: "amountpending",
      key: "amountpending",
      render: (value) => formatter.format(value),
    },
    {
      title: "Ticket Status",
      dataIndex: "ticketstatus",
      key: "ticketstatus",
    },
    {
      title: "Booking Given By",
      dataIndex: "bookinggivenby",
      key: "bookinggivenby",
    },
    {
      title: "Ticket Issued By",
      dataIndex: "ticketissuedby",
      key: "ticketissuedby",
    },
    {
      title: "Booking Portal",
      dataIndex: "bookingportal",
      key: "bookingportal",
    },
    { title: "PNR", dataIndex: "pnr", key: "pnr" },
    {
      title: "Travel Type",
      dataIndex: "traveltype",
      key: "traveltype",
    },
  ];

  const handleAddClick = () => {
    setTravelType("oneway");
    reset({
      bookingdate: "",
      clientname: "",
      passengername: "",
      noofpeople: "",
      departuretraveldate: "",
      departuresector: "",
      pnr: "",
      departureairlines: "",
      departureflightnumber: "",
      departurestarttime: "",
      departureendtime: "",
      returntraveldate: "",
      returnsector: "",
      returnairlines: "",
      returnflightnumber: "",
      returnstarttime: "",
      returnendtime: "",
      fare: "",
      fee: "",
      gst: "",
      bookinggivenby: "",
      ticketissuedby: "",
      bookingportal: "",
      amountreceived: "0",
      amountpending: "0",
      ticketstatus: "",
      amountreceiving: "0",
      paymentmode: "N/A",
      paymentreference: "N/A",
    });
    setIsClientForm(true);
  };

  const handleDiscard = () => {
    reset({
      bookingdate: "",
      clientname: "",
      passengername: "",
      noofpeople: "",
      departuretraveldate: "",
      departuresector: "",
      pnr: "",
      departureairlines: "",
      departureflightnumber: "",
      departurestarttime: "",
      departureendtime: "",
      returntraveldate: "",
      returnsector: "",
      returnairlines: "",
      returnflightnumber: "",
      returnstarttime: "",
      returnendtime: "",
      fare: "",
      fee: "",
      gst: "",
      bookinggivenby: "",
      ticketissuedby: "",
      bookingportal: "",
      amountreceived: "",
      amountpending: "",
      ticketstatus: "",
    });
    setIsClientForm(false);
    setIsEdit(false);
  };

  const fareValue = watch("fare");
  const feeValue = watch("fee");
  const gstValue = watch("gst");

  useEffect(() => {
    if (!isEdit) {
      const fare = parseFloat(fareValue) || 0;
      const fee = parseFloat(feeValue) || 0;
      const gst = parseFloat(gstValue) || 0;

      const total = fare + fee + gst;

      // Set amountpending dynamically
      setValue("amountpending", total.toString());
    }
  }, [fareValue, feeValue, gstValue, setValue, isEdit]);

  const amountreceivingValue = watch("amountreceiving");

  useEffect(() => {
    // console.log("amountreceivingValue", amountreceivingValue);

    // Check if the amountreceivingValue is an empty string first
    if (amountreceivingValue === "") {
      setValue("paymentmode", "");
      setValue("paymentreference", "");
    } else {
      const parsedamountreceivingValue = parseFloat(amountreceivingValue) || 0;

      // Check if the parsed value is 0
      if (parsedamountreceivingValue === 0) {
        setValue("paymentmode", "N/A");
        setValue("paymentreference", "N/A");
      } else {
        setValue("paymentmode", "");
        setValue("paymentreference", "");
      }
    }
  }, [amountreceivingValue, setValue]);

  useEffect(() => {
    if (isEdit) {
      // Set amountpending dynamically
      setValue("amountreceiving", "0");
      setValue("paymentmode", "N/A");
      setValue("paymentreference", "N/A");
    }
  }, [isEdit, setValue]);

  const onSubmit = async (data) => {
    // console.log("Connection Data:", data);

    const amountreceived = parseFloat(data.amountreceived) || 0;
    const amountreceiving = parseFloat(data.amountreceiving) || 0;
    const totalAmountReceived = amountreceived + amountreceiving;

    const fare = parseFloat(data.fare) || 0;
    const fee = parseFloat(data.fee) || 0;
    const gst = parseFloat(data.gst) || 0;
    const total = fare + fee + gst;

    const amountpending = total - totalAmountReceived;

    const pendingamount = parseFloat(data.amountpending || 0);
    const receivingamount = parseFloat(data.amountreceiving || 0);
    // console.log("amountpending Data:", pendingamount);
    // console.log("amountreceiving Data:", receivingamount);
    // console.log("amountpending Data:", amountpending);
    // console.log("total Data:", total);

    let ticketstatus;

    if (totalAmountReceived === 0) {
      ticketstatus = "Open";
    } else if (totalAmountReceived < total) {
      ticketstatus = "Pending";
    } else if (totalAmountReceived === total) {
      ticketstatus = "Closed";
    }
    // console.log("ticketstatus Data:", ticketstatus);

    if (receivingamount > pendingamount) {
      notification.warning({
        message: "Amount Receiving is More than Total",
        description:
          "The amount receiving exceeds the total fare. Please verify the payment amount.",
      });
      return;
    }

    const formatDateToIST = (gmtDate) => {
      const date = new Date(gmtDate);
      return date.toLocaleDateString("en-CA", { timeZone: "Asia/Kolkata" });
    };

    const formatTimeToIST = (gmtDate) => {
      const date = new Date(gmtDate);
      return date.toLocaleTimeString("en-GB", {
        timeZone: "Asia/Kolkata",
        hour12: false,
      });
    };

    const selClientName = data?.clientname;
    const selectedClient = clientsList.find(
      (client) => client.clientname === selClientName
    );
    const clientId = selectedClient ? selectedClient.clientid : null;

    const formattedDate = moment().format("DD-MM-YYYY");

    const pendingpayment = total - amountreceived - amountreceiving;

    const payloadPayment = {
      clientid: clientId,
      clientname: data.clientname,
      totalamount: total.toString(),
      totalstatus: "Receipt",
      receivedamount: data.amountreceiving.toString(),
      receivedstatus: "Invoiced",
      pendingamount: pendingpayment.toString(),
      pendingstatus: "Pending",
      paymentdate: formattedDate,
      paymentmode: data.paymentmode,
      paymentreference: data.paymentreference,
      // paymentmode: "UPI",
      // paymentreference: "7876543562711109",
    };
    // console.log("payload:", payloadPayment);

    const paymenturl = "payment";

    try {
      setIsSaveLoading(true);
      const response = await postApi(paymenturl, payloadPayment);
      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
        return;
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
      return;
    } finally {
      setIsSaveLoading(false);
    }

    const payload = {
      bookingdate: formatDateToIST(data.bookingdate),
      clientname: data.clientname,
      passengername: data.passengername,
      noofpeople: data.noofpeople.toString(),
      departuretraveldate: formatDateToIST(data.departuretraveldate),
      departuresector: data.departuresector,
      pnr: data.pnr,
      departureairlines: data.departureairlines,
      departureflightnumber: data.departureflightnumber,
      departurestarttime: formatTimeToIST(data.departurestarttime),
      departureendtime: formatTimeToIST(data.departureendtime),
      returntraveldate:
        travelType === "roundtrip"
          ? formatDateToIST(data.returntraveldate)
          : "9999-12-31",
      returnsector: travelType === "roundtrip" ? data.returnsector : "",
      returnairlines: travelType === "roundtrip" ? data.returnairlines : "",
      returnflightnumber:
        travelType === "roundtrip" ? data.returnflightnumber : "",
      returnstarttime:
        travelType === "roundtrip"
          ? formatTimeToIST(data.returnstarttime)
          : "00:00:00+00:00",
      returnendtime:
        travelType === "roundtrip"
          ? formatTimeToIST(data.returnendtime)
          : "00:00:00+00:00",
      fare: fare.toString(),
      fee: fee.toString(),
      gst: gst.toString(),
      total: total.toString(),
      bookinggivenby: data.bookinggivenby,
      ticketissuedby: data.ticketissuedby,
      bookingportal: data.bookingportal,
      totalreceiptstatus: "receipt",
      amountreceived: totalAmountReceived.toString(),
      amountreceivedstatus: "invoiced",
      amountpending: amountpending.toString(),
      amountpendingstatus: "pending",
      ticketstatus: ticketstatus,
      traveltype: travelType === "roundtrip" ? "roundtrip" : "oneway",
    };

    // console.log("Payload to send:", payload);
    const url = isEdit ? `booking/${currentId}` : "booking";
    try {
      setIsSaveLoading(true);
      const apiMethod = isEdit ? putApi : postApi;
      const response = await apiMethod(url, payload);
      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });
        const url = "booking";
        getBookingsList(url);
        setIsClientForm(false);
        setIsEdit(false);
        setIsSaveLoading(false);
      } else if (response?.status_code === 404) {
        notification.warning({
          message: "Booking Not Found...",
        });
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setIsSaveLoading(false);
    }
  };

  const handleNameClick = (record) => {
    // console.log("Resetting form with record data:", record);
    setIsClientForm(true);
    setCurrentId(record.bookingid);
    setIsEdit(true);
    setTravelType(record.traveltype);
    reset({
      bookingdate: record.bookingdate ? moment(record.bookingdate) : null,
      clientname: record.clientname,
      passengername: record.passengername,
      noofpeople: record.noofpeople.toString(),
      departuretraveldate: record.departuretraveldate
        ? moment(record.departuretraveldate)
        : null,
      departuresector: record.departuresector,
      pnr: record.pnr,
      departureairlines: record.departureairlines,
      departureflightnumber: record.departureflightnumber,
      departurestarttime: record.departurestarttime
        ? moment.utc(record.departurestarttime, "HH:mm:ss")
        : null,
      departureendtime: record.departureendtime
        ? moment.utc(record.departureendtime, "HH:mm:ss")
        : null,
      returntraveldate:
        record.returntraveldate && record.returntraveldate !== "9999-12-31"
          ? moment(record.returntraveldate)
          : "",
      returnsector: record.returnsector,
      returnairlines: record.returnairlines,
      returnflightnumber: record.returnflightnumber,
      returnstarttime:
        record.returnstarttime && record.returnstarttime !== "00:00:00Z"
          ? moment.utc(record.returnstarttime, "HH:mm:ss")
          : "",
      returnendtime:
        record.returnendtime && record.returnendtime !== "00:00:00Z"
          ? moment.utc(record.returnendtime, "HH:mm:ss")
          : "",
      fare: record.fare.toString(),
      fee: record.fee.toString(),
      gst: record.gst.toString(),
      bookinggivenby: record.bookinggivenby,
      ticketissuedby: record.ticketissuedby,
      bookingportal: record.bookingportal,
      amountreceived: record.amountreceived,
      amountpending: record.amountpending,
    });
  };

  const formFields = [
    {
      name: "bookingdate",
      label: "Booking Date",
      type: "date",
      icon: AiOutlineCalendar,
    },
    {
      name: "clientname",
      label: "Client Name",
      type: "select",
      icon: AiOutlineUser,
      options: clientOptions,
    },
    {
      name: "passengername",
      label: "Passenger Name",
      type: "input",
      icon: AiOutlineUser,
      editable: true,
    },
    {
      name: "noofpeople",
      label: "Number of People",
      type: "input",
      icon: AiOutlineTeam,
      editable: true,
    },
    {
      name: "departuretraveldate",
      label: "Departure Travel Date",
      type: "date",
      icon: AiOutlineCalendar,
    },
    {
      name: "departuresector",
      label: "Departure Sector",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "pnr",
      label: "PNR",
      type: "input",
      icon: AiOutlineIdcard,
      editable: true,
    },
    {
      name: "departureairlines",
      label: "Departure Airlines",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "departureflightnumber",
      label: "Departure Flight Number",
      type: "input",
      icon: AiOutlineNumber,
      editable: true,
    },
    {
      name: "departurestarttime",
      label: "Departure Start Time",
      type: "time",
      icon: AiOutlineClockCircle,
    },
    {
      name: "departureendtime",
      label: "Departure End Time",
      type: "time",
      icon: AiOutlineClockCircle,
    },
    {
      name: "fare",
      label: "Fare",
      type: "input",
      icon: BiRupee,
      editable: true,
    },
    {
      name: "fee",
      label: "Fee",
      type: "input",
      icon: BiRupee,
      editable: true,
    },
    {
      name: "gst",
      label: "GST",
      type: "input",
      icon: AiOutlineTag,
      editable: true,
    },
    {
      name: "bookinggivenby",
      label: "Booking Given By",
      type: "input",
      icon: AiOutlineUser,
      editable: true,
    },
    {
      name: "ticketissuedby",
      label: "Ticket Issued By",
      type: "input",
      icon: AiOutlineUser,
      editable: true,
    },
    {
      name: "bookingportal",
      label: "Booking Portal",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "amountreceived",
      label: "Amount Received",
      type: "input",
      icon: BiRupee,
      editable: false,
    },
    {
      name: "amountpending",
      label: "Amount Pending",
      type: "input",
      icon: BiRupee,
      editable: false,
    },
    {
      name: "amountreceiving",
      label: "Amount Receiving",
      type: "input",
      icon: BiRupee,
      editable: true,
    },
    {
      name: "paymentmode",
      label: "Payment Mode",
      type: "input",
      icon: MdPayment,
      editable: true,
    },
    {
      name: "paymentreference",
      label: "Payment Reference",
      type: "input",
      icon: MdOutlineReceipt,
      editable: true,
    },
  ];

  const returnFields = [
    {
      name: "returntraveldate",
      label: "Return Travel Date",
      type: "date",
      icon: AiOutlineCalendar,
    },
    {
      name: "returnsector",
      label: "Return Sector",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "returnairlines",
      label: "Return Airlines",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "returnflightnumber",
      label: "Return Flight Number",
      type: "input",
      icon: AiOutlineNumber,
      editable: true,
    },
    {
      name: "returnstarttime",
      label: "Return Start Time",
      type: "time",
      icon: AiOutlineClockCircle,
    },
    {
      name: "returnendtime",
      label: "Return End Time",
      type: "time",
      icon: AiOutlineClockCircle,
    },
  ];

  const [bookingFilterForm] = Form.useForm();
  const [bookingDateValue, setBookingDateValue] = useState(null);

  const handleBookingDateChange = (date, dateString) => {
    // Check if the date is valid
    if (date && date.isValid()) {
      setBookingDateValue(dateString);
    } else {
      console.warn("Invalid date selected");
    }
  };

  const handleFilterSubmit = (values) => {
    // console.log("Form values before submission:", values);
    const columns = [];
    const valuesArray = [];

    // Check and collect non-empty values
    if (values.clientname) {
      columns.push("clientname");
      valuesArray.push(values.clientname);
    }
    if (values.bookingid) {
      columns.push("bookingid");
      valuesArray.push(values.bookingid);
    }
    if (values.bookingdate) {
      columns.push("bookingdate");
      valuesArray.push(bookingDateValue);
    }

    if (columns.length > 0 && valuesArray.length > 0) {
      const columnsString = columns.join(",");
      const valuesString = valuesArray.join(",");
      const url = `filteredbooking?columns=${encodeURIComponent(
        columnsString
      )}&values=${encodeURIComponent(valuesString)}`;

      // console.log("Generated URL:", url);
      getBookingsList(url);
    } else {
      const url = "booking";
      // console.log("URL without params:", url);
      getBookingsList(url);
    }
  };

  const handleReset = () => {
    bookingFilterForm.resetFields();
    const url = "booking";
    getBookingsList(url);
  };

  const handleTravelTypeChange = (e) => {
    setTravelType(e.target.value);
  };

  const departureEndTimeIndex = formFields.findIndex(
    (field) => field.name === "departureendtime"
  );

  const finalFormFields =
    travelType === "roundtrip"
      ? [
          ...formFields.slice(0, departureEndTimeIndex + 1),
          ...returnFields,
          ...formFields.slice(departureEndTimeIndex + 1),
        ]
      : formFields;

  return (
    <>
      {isClientForm ? (
        <div className="flex space-x-6 p-2" style={{ height: "100vh" }}>
          <div className="flex-1">
            {/* Sticky header */}
            <div className="p-3 flex items-center justify-between sticky top-0 z-10 bg-white">
              <h2 className="com-heading">{`${
                isEdit ? "Edit" : "Create"
              } Ticketing Form`}</h2>
              <Radio.Group
                value={travelType}
                onChange={handleTravelTypeChange}
                options={[
                  { label: "One Way Trip", value: "oneway" },
                  { label: "Round Trip", value: "roundtrip" },
                ]}
              />
              <Button className="cus-btn" onClick={handleDiscard}>
                Discard
              </Button>
            </div>

            {/* Scrollable content */}
            <div
              className="mt-2 overflow-y-auto bg-[#f5faf3]"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <TicketingFormComponent
                formFields={finalFormFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonLoading={isSaveLoading}
                buttonName={"Save"}
                travelType={travelType}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex p-2" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Sticky Header */}
            <div className="py-3 px-1 flex items-center justify-between rounded-md sticky top-0 z-10 bg-white">
              <h2 className="com-heading">Ticketing List</h2>
              <Button className="cus-btn mr-4" onClick={handleAddClick}>
                Add
              </Button>
            </div>

            <Form
              form={bookingFilterForm}
              layout="inline"
              onFinish={handleFilterSubmit}
              className="pt-5 pb-5 pl-2 border border-gray-200 rounded-lg"
              style={{
                background: "linear-gradient(to right, #82e082, #226aa9)",
              }}
            >
              <Row style={{ flex: 1 }}>
                <Col>
                  <Form.Item name="bookingid">
                    <Input
                      placeholder="Enter Booking ID"
                      style={{ width: "150px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="clientname">
                    <Input
                      placeholder="Enter Client Name"
                      style={{ width: "150px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="bookingdate">
                    <DatePicker
                      value={
                        bookingDateValue
                          ? moment(bookingDateValue, "YYYY-MM-DD")
                          : null
                      }
                      onChange={handleBookingDateChange}
                      placeholder="Select Booking date"
                      format="YYYY-MM-DD"
                      style={{ width: "180px" }}
                    />
                  </Form.Item>
                </Col>

                {/* To align buttons to the right */}
                <Col className="flex ml-auto">
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="cus-btn"
                      icon={<FilterOutlined />}
                    >
                      Filter
                    </Button>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      onClick={handleReset}
                      className="cus-btn"
                      icon={<BiReset className="text-lg" />}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {/* Scrollable Table */}
            <div
              className="overflow-y-auto mt-4"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <Table
                dataSource={bookingList}
                columns={columns}
                scroll={{ x: "max-content" }}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleNameClick(record),
                })}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ticketing;
